import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby'

const marketoScriptId = 'mktoForms';

export default function MarketoForm({ formId, layoutPiece }) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!document.getElementById(marketoScriptId)) {
      loadScript();
    } else {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    isLoaded &&
      window.MktoForms2.loadForm(
        'https://223-FOQ-437.mktoweb.com',
        '223-FOQ-437',
        layoutPiece?.formId2
      );
  }, [isLoaded, formId]);

  const loadScript = () => {
    var s = document.createElement('script');
    s.id = marketoScriptId;
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://223-FOQ-437.mktoweb.com/js/forms2/js/forms2.min.js';
    s.onreadystatechange = function() {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        setIsLoaded(true);
      }
    };
    s.onload = () => setIsLoaded(true);
    document.getElementsByTagName('head')[0].appendChild(s);
  };

  return (
    <form id={`mktoForm_${layoutPiece?.formId2}`}></form>
  );
}
