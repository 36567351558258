import React from 'react'
import { Link, graphql } from 'gatsby'
import '../styles/product-landing.css'
import LinkArrowLight from '../assets/link-arrow-light.svg'
import HomebodyEntrata from '../assets/hb-entrata-w.svg'
import HomebodyEntrataB from '../assets/hb-entrata-b.svg'
import LinkArrow from '../assets/link-arrow.svg'
import VideoButton from '../assets/video-button.svg'
import DarkVideoButton from '../assets/dark-video-button.svg'
import ReactMarkdown from 'react-markdown'
import WistiaLink from './wistia-link'
import { symbolResize } from '../helper-functions.tsx'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { checkSvg } from '../helper-functions'

export default function ProductLanding({ layoutPiece }) {
  const gatsbyimage = getImage(layoutPiece?.image?.image)
  const image = layoutPiece?.image?.image
  const mediaLinkButton = layoutPiece?.mediaLinkButton
  const button = layoutPiece?.button 
  const link = layoutPiece?.link 

  function GetBackgroundColor() {
    switch (layoutPiece.backgroundColor) {
      case 'Black':
        return "product-hero dark-background background-hero-spacing"
      case 'Grey':
        return "product-hero grey-background background-hero-spacing"
      case 'Off-white':
        return "product-hero eggshell-background background-hero-spacing"
      default:
        return "product-hero background-hero-spacing"
    }
  }

  return (
    <div className={GetBackgroundColor()}>
      <div className="content-cap">
        <div className="product-landing-grid">
          <div className="product-text-align">
            <div className="product-text">
              {
                layoutPiece?.tagline === 'By Homebody' && layoutPiece?.backgroundColor === 'Black' ?
                  <div className="by-homebody-intro"><HomebodyEntrata /></div>
                :
                layoutPiece?.tagline === 'By Homebody' ?
                  <div className="by-homebody-intro"><HomebodyEntrataB /></div>
                :
                  <div className={layoutPiece.backgroundColor === 'Black' ? "small-text sub-head-title" : "small-text dark-sub-head-title"}>{layoutPiece?.tagline}</div>
              }


              <h1>{symbolResize(layoutPiece?.headlineText)}</h1>
              <ReactMarkdown className="markdown">{layoutPiece.bodyText.bodyText}</ReactMarkdown>
              <div className="button-holder">
                {
                  button && button?.fullUrl && layoutPiece?.backgroundColor === 'Black' ?
                    <a className="button-default solid-light-button" href={button?.fullUrl}>{button?.buttonText}</a>
                  :
                  button && button?.fullUrl ?
                    <a className="button-default solid-dark-button" href={button?.fullUrl}>{button?.buttonText}</a>
                  : ""
                }
                {
                  button && button?.slug && layoutPiece?.backgroundColor === 'Black' ?
                    <Link className="button-default solid-light-button" to={button?.slug}>{button?.buttonText}</Link>
                  :
                  button && button?.slug ?
                    <Link className="button-default solid-dark-button" to={button?.slug}>{button?.buttonText}</Link>
                  : ""
                }
                {
                  link && link?.fullUrl && layoutPiece?.backgroundColor === 'Black' ?
                    <a className="cta-link-default light-cta-link banner-link" href={layoutPiece.link.fullUrl}>{layoutPiece.link.text}<span className="link-arrow-icon"><LinkArrowLight /></span></a>
                  :
                  link && link?.fullUrl ?
                    <a className="cta-link-default dark-cta-link banner-link" href={link?.fullUrl}>{link?.text}<span className="link-arrow-icon"><LinkArrow /></span></a>
                  : ""
                }
                {
                  link?.slug && layoutPiece?.backgroundColor === 'Black' ?
                    <Link className="cta-link-default light-cta-link banner-link" to={link?.slug}>{link?.text}<span className="link-arrow-icon"><LinkArrowLight /></span></Link>
                  :
                  link && link?.slug ?
                    <Link className="cta-link-default dark-cta-link banner-link" to={link?.slug}>{link?.text}<span className="link-arrow-icon"><LinkArrow /></span></Link>
                  : ""
                }
              </div>
            </div>
          </div>
          <div className={layoutPiece?.image?.fitToFrame ? "product-image image-contain" : "product-image"}>
          {
            layoutPiece?.image ?
              checkSvg(image?.url) ?
                <img src={image?.url} alt={image?.description} className="product-image-file" />
                :
                <GatsbyImage image={gatsbyimage} alt={image?.description} className="product-image-file" />
              : ""
          }
          </div>
        </div>
        {mediaLinkButton ?
          <div className="video-button-feature">
            {
              mediaLinkButton?.wistiaVideoId ?
                <WistiaLink videoId={mediaLinkButton?.wistiaVideoId} text={mediaLinkButton?.mediaLinkCaption} textColor={layoutPiece.backgroundColor === 'Black' ? 'white' : 'black'} black={layoutPiece.backgroundColor === "Black" ? true : false} />
              : mediaLinkButton?.wistiaVideoId === null && mediaLinkButton?.mediaLinkUrl && layoutPiece?.backgroundColor === 'Black' ?
                <Link to={mediaLinkButton?.mediaLinkUrl}><span className="video-button-text">{mediaLinkButton?.mediaLinkCaption}</span><VideoButton className="video-button" /></Link>
              : mediaLinkButton?.wistiaVideoId === null && mediaLinkButton?.mediaLinkUrl && layoutPiece?.backgroundColor !== 'Black' ?
                <Link to={mediaLinkButton?.mediaLinkUrl}><span className="video-button-text"></span><DarkVideoButton className="video-button" /></Link>
              : ""
            }
          </div>
          : ""
        }
      </div>
    </div>
  );
}

export const query = graphql`
  fragment ProductBanner on ContentfulProductBanner {
    id
    backgroundColor
    headlineText
    bodyText {
      bodyText
    }
    image {
      fitToFrame
      image {
        gatsbyImageData(width: 900, placeholder: BLURRED)
        url
        description
      }
    }
    tagline
    mediaLinkButton {
      mediaLinkUrl
      mediaLinkCaption
      wistiaVideoId
    }
    button {
      buttonOutlined
      buttonText
      fullUrl
      slug
    }
    link {
      text
      fullUrl
      slug
    }
  }
`
