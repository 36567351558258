import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import RichText from './rich-text'
import MarketoForm from '../components/marketo-form'
import '../styles/form-landing.css'
const contentful = require('contentful')

export default function FormLanding({ layoutPiece }) {
    const [ richTextEntry, setRichTextEntry ] = useState()
    const content = layoutPiece?.overviewText 
    const backgroundColor = layoutPiece?.backgroundColor
    const richText = {
        richTextData: content?.raw ? JSON.parse(content.raw) : null,
        content: richTextEntry?.items[0]?.fields?.overviewText?.content ? richTextEntry?.items[0]?.fields?.overviewText?.content : '',
        assetSize: '100%'
    }
    function GetBackgroundColor() {
        switch (backgroundColor) {
            case 'Black':
                return "form-landing-background dark-background"
            case 'Grey':
                return "form-landing-background grey-background"
            case 'Off-white':
                return "form-landing-background eggshell-background"
            default:
                return "form-landing-background"
        }
    }
    const backgroundStyle = {
        backgroundImage: `linear-gradient(#191919c0, #191919c0), url(${layoutPiece?.backgroundImage?.image?.url})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }
    useEffect(() => {
        const client = contentful.createClient({
            space: process.env.CONTENTFUL_SPACE_ID,
            accessToken: process.env.CONTENTFUL_ACCESS_TOKEN
        })
        const getrichTextEntry = async (title) => {
            if (!richTextEntry) {
                await client.getEntries({
                content_type: 'formLandingComponent',
                limit: 1,
                include: 10,
                'fields.title': title
                })
                .then((entry) => setRichTextEntry(entry))
                .catch(console.error)
            } else {
                null
            }
        }
        getrichTextEntry(layoutPiece?.title)
    }, [])
    return (
        <div className={GetBackgroundColor()} style={layoutPiece?.backgroundImage ? backgroundStyle : {}}>
            <div className='content-cap'>
                <div className={backgroundColor === 'Black' ? 'form-landing-content-dark' : 'form-landing-content'}>
                    <div className='form-landing-left'>
                        <h1 className='form-landing-desktop-h1'>{layoutPiece?.headlineText}</h1>
                        {
                            layoutPiece?.overviewText ?
                                <div className='rich-text'>
                                    <RichText data={richText} />
                                </div>
                                : ''
                        }
                        {
                            layoutPiece?.imageWithText ?
                                layoutPiece?.imageWithText.map((tile) => {
                                    return (
                                        <div key={Math.random()} className='form-landing-quote-tile'>
                                            <div className='form-landing-quote-tile-image-container'>
                                                <img src={tile.image?.image?.url} alt={tile.image?.image?.description} />
                                            </div>
                                            <div className='form-landing-quote-tile-text-container'>
                                                <p className='form-landing-quote-text'>{tile.headlineText}</p>
                                                <p className='form-landing-title-text'>{tile.bodyText}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                : ''
                        }
                    </div>
                    <div className='form-landing-right'>
                        {
                            layoutPiece?.formHeaderText ?
                                <h2>{layoutPiece?.formHeaderText}</h2>
                                :''
                        }
                        <MarketoForm layoutPiece={layoutPiece} />
                    </div>
                    <h1 className='form-landing-mobile-h1'>{layoutPiece?.headlineText}</h1>
                </div>
            </div>
        </div>
    )
}

export const query = graphql`
  fragment FormLanding on ContentfulFormLandingComponent {
    id
    title
    headlineText
    overviewText {
        raw
        references {
            contentful_id
            url
            description
        }
    }
    imageWithText {
        image {
            image {
                url
                description
            }
        }
        headlineText
        bodyText
    }
    backgroundImage {
        image {
            url
            description
        }
    }
    backgroundColor
    formHeaderText
    formId2
  }
`