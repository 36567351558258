import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
//import component pieces
import GenericContentArea from '../templates/generic-content-area'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function GenericPage({ data, pageContext }) {
  const layoutPieces = data.contentfulGenericPageTemplate.pageComponents

  return (
    <Layout header={data?.contentfulGenericPageTemplate?.header} footer={data?.contentfulGenericPageTemplate?.footer}>
      <GenericContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta name="description" content={data?.contentfulGenericPageTemplate?.metaDescription} />
    <title>{data?.contentfulGenericPageTemplate?.metaTitle}</title>
    <meta property="og:title" content={data?.contentfulGenericPageTemplate?.ogMetaTitle ? data.contentfulGenericPageTemplate.ogMetaTitle : data?.contentfulGenericPageTemplate?.metaTitle} />
    <meta property="og:description" content={data?.contentfulGenericPageTemplate?.ogMetaDescription ? data.contentfulGenericPageTemplate.ogMetaDescription : data?.contentfulGenericPageTemplate?.metaDescription} />
    <meta property="og:type" content={data?.contentfulGenericPageTemplate?.ogMetaType} />
    <meta property="og:url" content={data?.contentfulGenericPageTemplate?.slug ? `https://www.entrata.com${data?.contentfulGenericPageTemplate?.slug}` : undefined} />
    <meta property="og:image" content={data?.contentfulGenericPageTemplate?.ogMetaImage?.image ? data?.contentfulGenericPageTemplate?.ogMetaImage?.image?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query GenericComponentPieces($slug: String!) {
    contentfulGenericPageTemplate(slug: { eq: $slug }) {
      slug
      header
      footer
      metaDescription
      metaTitle
      ogMetaTitle
      ogMetaDescription
      ogMetaType
      ogMetaImage {
        image {
          url
        }
      }
      pageComponents {
        ... on Node {
          internal {
            type
          }
          ... on ContentfulProductBanner {
            ...ProductBanner
          }
          ... on ContentfulFormLandingComponent {
            ...FormLanding
          }
          ... on ContentfulLogoDisplay {
            ...LogoDisplay
          }
          ... on ContentfulSimpleStatComponent {
            ...SimpleStat
          }
          ... on ContentfulGlobalIntro {
            ...GlobalIntro
          }
          ... on ContentfulPortfolioCarousel {
            ...ClientFeature
          }
          ... on ContentfulDuplexFeature {
            ...DuplexFeature
          }
          ... on ContentfulFormComponent {
            ...FormsComponent
          }
          ... on ContentfulVideoFeature {
            ...VideoFocus
          }
          ... on ContentfulLegalPageComponent {
            ...LegalPageComponent
          }
          ... on ContentfulRichTextComponent {
            ...RichTextComponent
          }
          ... on ContentfulMiniCaseStudySlider {
            ...CaseStudySlider
          }
          ... on ContentfulBucketComponent {
            ...BucketComponent
          }
          ... on ContentfulImageSliderComponent {
            ...ImageSliderComponent
          }
          ... on ContentfulAttributeListComponent {
            ...AttributeListComponent
          }
          ... on ContentfulPopUpComponent {
            ...Popup
          }
        }
      }
    }
  }
`
